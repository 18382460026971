import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="Contact" />
      <h1>Workshop: Kruzic Krizic</h1>
      <p>If you want to talk you can contact me via good old email.</p>
      <p>
        For personal <a href="mailto:marko@korevec.net">marko@korevec.net</a>
      </p>
      <p>
        For business{" "}
        <a href="mailto:business@korevec.net">business@korevec.net</a>
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
  }
`
